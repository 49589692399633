<template>
  <div class="box box-primary">
    <div class="box-header with-border">
      <h3 class="box-title">
        {{ $t('string.occupancyDocument') }}
      </h3>
      <div class="box-tools">
        <AsoraBackButton />
      </div>
    </div>
    <div class="box-body">
      <template v-if="occupancy !==null">
        <UploadMedia
          :model-uuid="occupancy.uuid"
          :button-label="$t('string.choose')"
          model-name="occupancy"
        />
      </template>
      <div class="row">
        <div class="col-md-12">
          <button
            class="btn btn-success"
            @click="backToList"
          >
            <i class="fa fa-check-circle" /> {{ $t('button.done') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadMedia from "@/components/UploadMedia"

export default {
  name: "Upload",
  components: {UploadMedia},
  metaInfo() {
    return {
      title: this.$t('string.openConstructionDocument'),
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME,
    }
  },
  data() {
    return {
      occupancy_uuid: null,
      occupancy: null,
      options: [],
      validations: null
    }
  },
  computed: {
    api() {
      return `${this.baseUrl}/api/backend/certificate-occupancy-province`
    },
  },
  mounted() {
    this.occupancy_uuid = this.$route.params.occupancy_uuid
    this.getMediaOptions()
    this.getOccupancy()
  },
  methods: {
    isAllowInspectionApp(item) {
      // eslint-disable-next-line no-prototype-builtins
      return item.hasOwnProperty('allow_inspection_app') && item.allow_inspection_app === false
    },
    getMediaOptions() {
      this.$axios.post(this.$base_api + "/api/backend/media/get-option", {
        model_name: 'occupancy'
      }).then((response) => {
        this.options = response.data.data
      }).catch((error) => {
        this.onResponseError(error)
      })
    },

    getOccupancy() {
      this.validations = null
      this.$axios.post(`${this.api}/show`, {
        occupancy_uuid: this.occupancy_uuid
      }).then((response) => {
        this.occupancy = response.data.data.occupancy
      }).catch((error) => {
        if (error.response.status === 422) {
          this.validations = error.response.data.errors
        } else {
          this.onResponseError(error)
        }
      })
    },

    previewLetter(type) {
      window.open(`${this.baseUrl}/certificates/occupancy/${type}/${this.occupancy_uuid}`, '_blank')
    },

    backToList() {
      this.$router.replace({name: 'list-certificate-occupancy-province'})
    }
  }
}
</script>

<style scoped>

</style>
